<template>
  <div class="w-full h-full">
    <Delegation mode="admin" />
  </div>
</template>

<script>
import Delegation from "@/components/delegation";

export default {
  name: 'DelegationsView',
  components: {
    Delegation
  }
}
</script>